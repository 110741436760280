import React from 'react';
import HomePage from "./Pages/HomePage/HomePage";
import ContactPage from "./Pages/ContactPage/Contact";
import ServicesPage from "./Pages/ServicesPage/Services";
import AboutPage from "./Pages/AboutPage/About";
import ProjectsPage from "./Pages/ProjectsPage/Projects";
// import LogInPage from "./Pages/LogInPage/LogIn";
// import ResetPassword from "./Pages/ResetPassword/Reset";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="*" element={<HomePage />} />
        {/* <Route path="/login" element={<LogInPage />} /> */}
        {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
      </Routes>
      
    </Router>
  );
}

export default App;
