import React, { useState } from 'react';
import './Header.css';
import "../../assets/css/style.css";
import digigologo from "../../assets/images/digigologo.png";
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [menuActive, setMenuActive] = useState(false);

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    return (
        <header className="navbar-container">
            <Link to='/' className="logo-block">
                <img src={digigologo} alt="Logo" className="logo-digigo" />
            </Link>

            <div className="burger-menu" onClick={toggleMenu}>
                &#9776; 
            </div>

            <nav className={`menu-elements ${menuActive ? 'menu-active' : ''}`}>
                <div className="menu-items">
                    <Link to="/" className={({ isActive }) => (isActive ? 'active' : '')}>Home</Link>
                    <Link to="/projects" className={({ isActive }) => (isActive ? 'active' : '')}>Projects</Link>
                    <Link to="/about" className={({ isActive }) => (isActive ? 'active' : '')}>About</Link>
                    <Link to="/services" className={({ isActive }) => (isActive ? 'active' : '')}>Services</Link>
                    <Link to="/contact" className={({ isActive }) => (isActive ? 'active' : '')}>Contact</Link>
                </div>
                {/* <div className="language">
                    <button onClick={() => console.log("Switch to EN")} className="lang-button">EN</button>
                    <span>|</span>
                    <button onClick={() => console.log("Switch to GR")} className="lang-button">GR</button>
                </div> */}
            </nav>
        </header>
    );
};

export default Navbar;
