import React from 'react';
import "./Contact.css";
import "../../assets/css/style.css";
import Header from "../../components/HeaderComponent/Header";
import Footer from "../../components/FooterComponent/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function Contact() {
  // const [formData, setFormData] = useState({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   message: "",
  // });
  
  // const [formStatus, setFormStatus] = useState("");
  
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };
  
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   if (formData.firstName && formData.lastName && formData.email) {
  //     console.log("Form submitted:", formData);
  //     setFormStatus("Form successfully submitted!");
      
  //     setFormData({
  //       firstName: "",
  //       lastName: "",
  //       email: "",
  //       message: "",
  //     });
  //   } else {
  //     setFormStatus("Please fill in all fields.");
  //   }
  // };

  return (
    <>
      <div>
        <Header />
        <div className='contact-container'>
          <div className='contact-header'>
            <p>Contact <span>Us</span></p>
            <p>Contact us today to start your digital journey!</p>
          </div>
          <div className='contact-content'>
            <div className='contact-details'>

              {/* <p>Get In <span>Touch</span></p> */}
              {/* <p className='contact-description'>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document.</p> */}
              <div className='contact-info'>
                <div className='contact-item'>
                  {/* <FontAwesomeIcon className='contact-icon' icon={faLocationDot} /> */}
            <FontAwesomeIcon className='contact-icon' icon={faWhatsapp} />
                  {/* <p className='contact-text'>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.</p> */}
                  <a className='contact-text' href="https://wa.me/994505782436" target="_blank" rel="noopener noreferrer">+994 50 578 24 36</a>
                </div>
                <div className='contact-item'>
                  <FontAwesomeIcon className='contact-icon' icon={faEnvelope} />
                  <p className='contact-text'>info@digigo.work</p>
                </div>
                <div className='contact-item'>
                  <FontAwesomeIcon className='contact-icon' icon={faPhoneVolume} />
                  <a href={`tel:+37253857434}`} target="_blank" rel="noopener noreferrer" className='contact-text'>+3 725 385 74 34</a>
                </div>
              </div>
            </div>
            {/* <div className='contact-form-wrapper'>
            <p>Get in <span>Touch</span></p>
              <form className='contact-form' onSubmit={handleSubmit}>
                <label htmlFor="firstName">First Name</label>
                <input
                  className='form-input'
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="lastName">Last Name</label>
                <input
                  className='form-input'
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="email">Email</label>
                <input
                  className='form-input'
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="message">Message</label>
                <textarea
                  className='form-textarea'
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                />

                <button className='form-submit-btn' type="submit">Submit</button>
              </form>

              {formStatus && <p className='form-status-message'>{formStatus}</p>}
            </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Contact;
