import React from 'react';
import "../../assets/css/style.css";
import "./Footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faTiktok, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <div className='footer'>
      <div className='footer-left-side'>
        <p>DIGIGO</p>
        {/* <p>© 2022 - Lorem ipsum dolor sit amet - Lorem ipsum -dolor sit- amet · <a href="tel:+1234567890">+00.000000000 · +00.00000000 ·</a></p> */}
        <div className='footer-icons'>
          {/* <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className='footer-icon' icon={faFacebook} />
          </a> */}
          <a href="https://www.instagram.com/digigo.ee?igsh=a3lyaXc3NGtjd3M4" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className='footer-icon' icon={faInstagram} />
          </a>
          <a href="https://www.linkedin.com/feed/update/urn:li:activity:7255652917431480320" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className='footer-icon' icon={faLinkedin} />
          </a>
          <a href="https://www.tiktok.com/@digigo_?_t=8rXkWehtUq9&_r=1" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className='footer-icon' icon={faTiktok} />
          </a>
          <a href="https://wa.me/994505782436" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className='footer-icon' icon={faWhatsapp} />
           
           
          </a>
          <a href={`tel:+37253857434}`} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className='footer-icon' icon={faPhone} />

           
          </a>
        </div>
      </div>
      {/* <div className='footer-right-side'>
        <div className='footer-column'>
          <p>TITLE</p>
          <a href="№">PROJECTS</a>
          <a href="№">COLLECTIONS</a>
          <a href="№">CONTACTS</a>
        </div>
        <div className='footer-column'>
          <p>TITLE</p>
          <a href="№">PROJECTS</a>
          <a href="№">COLLECTIONS</a>
          <a href="№">CONTACTS</a>
        </div>
        <div className='footer-column'>
          <p>TITLE</p>
          <a href="№">PROJECTS</a>
          <a href="№">COLLECTIONS</a>
          <a href="№">CONTACTS</a>
        </div>
      </div> */}
    </div>
  );
}

export default Footer;
