import React from 'react';
import "./Services.css";
import "../../assets/css/style.css";
import Header from "../../components/HeaderComponent/Header";
import Footer from "../../components/FooterComponent/Footer";
import dataanalitik from './icon/dataAnalitik.webp'
import cybersecurity from './icon/cybersecurity.webp'
import mobilveb from './icon/mobilveb.webp'
import uxui from './icon/uxui.webp'
import entenior from './icon/entenior.webp'
import smm from './icon/smm.webp'
function Services() {
  return (
    <>
      <div>
        <Header />
        <div className='services-container'>
          <div className='services-header'>
            <p>Our <span>Services</span></p>
            {/* <p>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.</p> */}
          </div>
           



          <div className="services-content">
            {/* <div className='service-row'> */}
              <div className='service-item'>
                <img src={dataanalitik} alt="" />
                <p >Data analitika</p>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p> */}
              </div>
              <div className='service-item'>
                <img src={cybersecurity} alt="" />
                <p>cybersecurity</p>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p> */}
              </div>
              <div className='service-item'>
                <img src={mobilveb} alt="" />
                <p>Mobile and Web Development</p>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p> */}
              </div>

            {/* </div> */}
            {/* <div className='service-row'> */}
              <div className='service-item'>
                <img src={uxui} alt="" />
                <p>Ux/Ui Design</p>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p> */}
              </div>
              <div className='service-item'>
                <img src={entenior} alt="" />
                <p>İnterior/Exterior designer</p>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p> */}
              </div>
              <div className='service-item'>
                <img src={smm} alt="" />
                <p>Sosial media marketing (SMM)</p>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p> */}
              </div>

            {/* </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Services;
